import React from "react";
import { Modal, notification, Button } from "antd";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { reduxForm } from "redux-form";
import PreviewJobReport from "./JobReport/PreviewJobReport";
import * as actions from "../../../actions/SAJobMgmtAction";
import { Strings } from "../../../dataProvider/localize";
import { handleFocus } from "../../../utils/common";
import ViewReportDocModel from "./viewReportDocModel";

import { withRouter } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';

import jobStyles from "../Job/JobStyle.module.css";
import styles from "./ReportsStyles.module.css";

import v2Styles from "../v2Styles.module.css";
import "../v2Styles.css"
import EmailJobReport from "./JobReport/EmailJobReport";
import JobReportRecordsList from "./JobReport/JobReportRecordsList";
import CleaningReportHistory from "./CleaningReportHistory";
import JobReportDocumentsList from "./JobReport/JobReportDocumentsList";
import "../../dashboard/serviceAgentJobMgmt/jobReport/jobReportPdf.css";  // breaking stlyes do not remove.
const imageTypes = ['png', 'jpg', 'jpeg', 'webp', 'bmp'];

class CleaningReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewTaskFiles: false,
      taskFiles: "",
      fileType: '',
      selectedRowCheck: [],
      selectedDocRowCheck: [],
      isSignOff: false,
      emailJobReportModal: false,
      historyKey: 0,
      emailHistoryData: null
    };
  }

  componentDidMount() {
    // this.props.change(`job.id`,48);
    const jobId = this.props.jobId;
    this.props.action.getJobReport(jobId, this.props.job.job_number);

    this.props.action.getJobDetails(jobId).then(() => {
      this.setState({ allTasks: this.getAllTasks(this.props.location.state) });
    });
    //this.props.action.getAllJobDetail
  }

  loadJobReport = (removeSelection = false) => {
    this.props.action.getJobReport(this.props.jobId, this.props.job.job_number);
    this.props.action.getJobDetails(this.props.jobId).then(() => {
      this.setState({
        allTasks: this.getAllTasks(this.props.location.state),
        ...(removeSelection ? {
          selectedRowCheck: [],
          selectedDocRowCheck: []
        } : {})
      });
    });
  }

  getAllTasks = (props) => {
    let allTasks = [];
    let sites =
      props && props.job && props.job.sites
        ? props.job.sites
        : this.props.job.sites;
    sites &&
      sites.forEach((site) => {
        allTasks = [...allTasks, ...site.tasks];
      });
    return allTasks;
  };

  signOffToggle = () => {

    this.setState({ ...this.state, isSignOff: !this.state.isSignOff })

  }

  handleViewImageCancel = () => {
    this.setState({
      viewTaskFiles: false,
      taskFiles: [],
    });
  };

  showModal = () => {
    if ((this.state.selectedRowCheck && this.state.selectedRowCheck.length > 0) ||
      (this.state.selectedDocRowCheck.length > 0 && this.state.selectedDocRowCheck && this.state.selectedDocRowCheck.length >= 0)) {
      this.setState({ visible: true });
    } else {
      notification.warning({
        message: "Please select atleast one Cleaning report and Document.",
        description: "",
        onClick: () => { },
        className: "ant-warning",
      });
    }
  };

  handleOk = (e) => {
    this.setState({ emailJobReportModal: true });
  }

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  rowCheckClick = (data) => {
    const selectedCheckRowNew = [...this.state.selectedRowCheck];
    var index = selectedCheckRowNew.findIndex((x) => x.id.toString() === data.id.toString());
    if (index === -1) {
      selectedCheckRowNew.push(data);
      this.setState({
        ...this.state,
        selectedRowCheck: selectedCheckRowNew,
      });
    } else if (selectedCheckRowNew) {
      if (index > -1) {
        selectedCheckRowNew.splice(index, 1);
        this.setState({
          ...this.state,
          selectedRowCheck: selectedCheckRowNew,
        });
      }
    }
  };

  headerDocClick = (status) => {
    if (status) {
      let AllRowIds = this.props.jobDocument.map((report) => report);
      this.setState({
        ...this.state,
        selectedDocRowCheck: AllRowIds,
      });
    } else {
      this.setState({
        ...this.state,
        selectedDocRowCheck: [],
      });
    }
  };

  rowDocCheckClick = (data) => {
    const selectedCheckRowNew = [...this.state.selectedDocRowCheck];
    var index = selectedCheckRowNew.findIndex((x) => x.id.toString() === data.id.toString());
    if (index === -1) {
      selectedCheckRowNew.push(data);
      this.setState({
        ...this.state,
        selectedDocRowCheck: selectedCheckRowNew,
      });
    } else if (selectedCheckRowNew) {
      if (index > -1) {
        selectedCheckRowNew.splice(index, 1);
        this.setState({
          ...this.state,
          selectedDocRowCheck: selectedCheckRowNew,
        });
      }
    }
  };

  handleDocView = (files) => {
    if (files && files.length > 0) {
      let fileType = files.substring(files.lastIndexOf('.') + 1, files.length) || files;
      this.setState({
        fileType: fileType.toLowerCase(),
        viewTaskFiles: true,
        taskFiles: files,
      });
    }
  };

  handleDocCancel = () => {
    this.setState({
      viewDoc: false,
      taskFiles: [],
    });
  };

  /////////download doc
  downLoadDoc = (url) => {
    fetch(url).then((res) => {
      if (!res.ok) {
        notification.error({
          message: Strings.error_title,
          description: Strings.file_download_error,
          className: "ant-error",
        })
      } else {
        res.blob().then((blob) => {
          var file = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = file;
          let fileName = url.substring(url.lastIndexOf('/') + 1).toString();
          a.download = fileName;
          a.click();
          a.remove();
        })
      }
    }).catch(() => {
      notification.error({
        message: Strings.error_title,
        description: Strings.file_download_error,
        className: "ant-error",
      })
    })
  }

  checkAllRecords = (e) => {
    if (e.target.checked) {
      this.setState({ selectedRowCheck: this.props.jobReports })
    } else {
      this.setState({ selectedRowCheck: [] })
    }
  }

  onEmailReport = (historyData) => {
    this.setState({
      emailHistoryData: historyData,
      selectedRowCheck: historyData.job_cleaning_report_data && historyData.job_cleaning_report_data.job_cleaning_report ? historyData.job_cleaning_report_data.job_cleaning_report : [],
      selectedDocRowCheck: historyData.job_cleaning_report_data && historyData.job_cleaning_report_data.job_report_documents ? historyData.job_cleaning_report_data.job_report_documents : []
    }, () => {
      this.handleOk()
    })
  }

  downloadReport = () => {
    if ((this.state.selectedRowCheck && this.state.selectedRowCheck.length > 0) ||
      (this.state.selectedDocRowCheck.length > 0 && this.state.selectedDocRowCheck && this.state.selectedDocRowCheck.length >= 0)) {
      this.props.action.createReportHistory({}, {
        job_report_ids: JSON.stringify(this.state.selectedRowCheck.map(x => x.id)),
        job_id: this.props.jobId,
        job_document_ids: JSON.stringify(this.state.selectedDocRowCheck.map(x => x.id)),
        saveHistory: 0
      }, true).then(res => {
        if (res.pdf_link) {
          this.downLoadDoc(res.pdf_link);
        } else {
          notification.error({
            message: "Failed to Download Job Report",
            className: "ant-error"
          })
        }
      }).catch(err => {
        if (err) {
          console.log(err);
          notification.error({
            message: "Failed to Download Job Report",
            className: "ant-error"
          })
        }
      })
    } else {
      notification.warning({
        message: "Please select atleast one Cleaning report and Document.",
        description: "",
        onClick: () => { },
        className: "ant-warning",
      });
    }
  }

  onTaskChange = (reports, e) => {
    let ids = reports.map(x => x.id);
    if (e.target.checked) {
      let map = [...new Set([...this.state.selectedRowCheck.map(x => x.id), ...ids])];
      this.setState(s => ({ ...s, selectedRowCheck: [...this.props.jobReports.filter(x => map.indexOf(x.id) !== -1)] }))
    } else {
      this.setState(s => ({ ...s, selectedRowCheck: [...s.selectedRowCheck.filter(x => ids.indexOf(x.id) === -1)] }))
    }
  }

  onAllSelect = (v) => {
    if (v) {
      this.setState(s => ({ ...s, selectedRowCheck: [...this.props.jobReports] }));
    } else {
      this.setState(s => ({ ...s, selectedRowCheck: [] }));
    }
  }
  render() {
    const { job } = this.props;

    return (
      <div className={styles.DocsContainer}>
        <div className="preview-section container-fluid px-2">
          <div className="row">
            <div className="col-6">
              <span className={jobStyles.SectionHeading}>Report Info</span>
            </div>
          </div>
          <div className={jobStyles.card}>
            <div className="row">
              <div className="col-12 col-lg-6 col-md-6 col-sm-12 pr-0">
                <div className={jobStyles.SectionHeading}>By</div>
                <div className={jobStyles.card}>
                  <ul className="mb-0">
                    <li className={styles.Content}><span className={styles.Title}>Documents Prepared By: </span>{job.admin_name}</li>
                    <li className={styles.Content}><span className={styles.Title}> Company: </span>{job.org_name} </li>
                    <li className={styles.Content}><span className={styles.Title}> Address: </span>{job.address}</li>
                    <li className={styles.Content}><span className={styles.Title}> Mobile: </span>{job.admin_phone_number} </li>
                    <li className={styles.Content}><span className={styles.Title}> Email: </span>{job.admin_email_address} </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-lg-6 col-md-6 col-sm-12 pl-2">
                <div className={jobStyles.SectionHeading}>To</div>
                <div className={jobStyles.card}>
                  <ul className="mb-0">
                    <li className={styles.Content}><span className={styles.Title}>Attention: </span>{job.client_person_name} </li>
                    <li className={styles.Content}><span className={styles.Title}> Company: </span>{job.client_name} </li>
                    <li className={styles.Content}><span className={styles.Title}> Address: </span>{job.client_address} </li>
                    <li className={styles.Content}><span className={styles.Title}> Mobile: </span>{job.client_person_phone}</li>
                    <li className={styles.Content}><span className={styles.Title}> Email: </span>{job.client_person_email}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className={`${jobStyles.SectionHeading} mt-2`}>Job Details</div>
            <div className={jobStyles.card}>
              {job && job.sites && job.sites.length > 0 &&
                job.sites.map((site) => {
                  return <div className="row" key={site.id}>
                    <div className="col-3 d-flex" style={{ columnGap: "5px" }}>
                      <div className={styles.Title}>Site Name: </div>
                      <div className={styles.Content}>{site.site_name}</div>
                    </div>
                    <div className="col-6 d-flex" style={{ columnGap: "5px" }}>
                      <div className={styles.Title}>Site Address: </div>
                      <div className={styles.Content}>{site.street_address} {site.city},{site.state},{site.country}  </div>
                    </div>
                    <div className="col-3">
                      <div>
                        <span className={styles.Title}>Purchase Order: </span><span className={styles.Content}>{job.PO_number}{" "}</span>
                      </div>
                    </div>
                  </div>
                })}
            </div>
          </div>

          <Modal
            visible={this.state.viewDoc}
            className="cs-modal-v2"
            zIndex="99999"
            style={{ left: 40, top: 20 }}
            footer={null}
            width="60%"
            closeIcon={<div className={jobStyles.ModalCloseIcon}><CloseIcon /></div>}
            onCancel={this.handleDocCancel}
          >
            <ViewReportDocModel taskFiles={this.state.taskFiles} />
          </Modal>
        </div>
        <div>
          <div>
            <div className="row">
              <div className="col-lg-12">
                <div className="mt-2 px-2">
                  <div>
                    <div className={jobStyles.SectionHeading}>Job Details</div>
                    <div className={jobStyles.card}>
                      <div className="container-fluid pl-0">
                        <JobReportRecordsList
                          jobId={this.props.jobId}
                          basePath={this.props.basePath}
                          reports={this.props.jobReports}
                          refreshReports={this.loadJobReport}
                          tasks={this.state.allTasks}
                          checkedRecords={this.state.selectedRowCheck.map(x => x.id.toString())}
                          onChange={this.rowCheckClick}
                          onTaskChange={this.onTaskChange}
                          onAllSelect={this.onAllSelect}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="mt-2">
                    <div className={jobStyles.SectionHeading}>Additional Documents </div>
                    <div className={jobStyles.card}>
                      <div className="accordion" id="accordionExample">
                        <div className="table-responsive">
                          <JobReportDocumentsList
                            documents={this.props.jobDocument}
                            basePath={this.props.basePath}
                            refreshReports={this.loadJobReport}
                            onPreview={this.handleDocView}
                            jobId={this.props.jobId}
                            onDownload={this.downLoadDoc}
                            checked={this.state.selectedDocRowCheck}
                            onChange={this.rowDocCheckClick}
                            selectAll={this.headerDocClick}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* save and preview button */}
                <div className="all-btn d-flex justify-content-end mt-4 sc-doc-bnt mr-4">
                  <div className="btn-hs-icon">
                    <button
                      type="button"
                      className={jobStyles.SecondaryButton}
                      onClick={() => this.downloadReport()}>
                      Download Report
                    </button>
                  </div>
                  <div className="btn-hs-icon">
                    <button
                      type="button"
                      className={jobStyles.PrimaryButton}
                      onClick={this.showModal}>
                      {Strings.preview_btn}
                    </button>
                  </div>
                </div>

                {/* Previous Reports Section */}
                <div className="px-2 pb-3">
                  <div className={`${jobStyles.SectionHeading} mt-2`}>Previous Reports</div>
                  <div className={jobStyles.card}>
                    <CleaningReportHistory
                      updateKey={this.state.historyKey}
                      jobId={this.props.jobId}
                      onEmailReport={this.onEmailReport}
                    />
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

        {/* for view full image */}
        <Modal
          visible={this.state.viewTaskFiles}
          className="cs-modal-v2"
          centered
          footer={null}
          width={768}
          closeIcon={<div className={jobStyles.ModalCloseIcon}><CloseIcon /></div>}
          onCancel={this.handleViewImageCancel}
        >
          <div className="container py-3">
            <div className="row">
              <div className="col-12">
                <div className="d-flex align-items-center justify-content-center" style={{ height: '768px', backgroundColor: '#000000' }}>
                  {this.state.fileType && imageTypes.includes(this.state.fileType) ?
                    <img src={this.state.taskFiles} alt="" className="img-fluid" style={{ maxHeight: '760px' }} />
                    : this.state.fileType && this.state.fileType === "pdf" ?
                      <div style={{ width: '750px', height: '768px' }} >
                        <embed src={this.state.taskFiles} type="application/pdf" style={{ width: '100%', height: '100%' }} />
                      </div>
                      : <div className="text-center" style={{ width: '400px', color: '#ffffff' }}>
                        <span className="h6 font-weight-normal">Preview Not Available.</span>
                        <div>
                          <Button target="_blank" href={this.state.taskFiles} className="mt-4">Download</Button>
                        </div>
                      </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </Modal>


        <Modal
          className="cs-modal-v2"
          visible={this.state.visible}
          bodyStyle={{ paddingRight: "0px", paddingLeft: "0px" }}
          centered
          onCancel={this.handleCancel}
          style={{ maxHeight: "95vh" }}
          // height="95vh"
          width="80vw"
          footer={null}
          destroyOnClose={true}
          maskClosable={false}
          closeIcon={<div className={styles.ModalCloseIcon}><CloseIcon /></div>}
        >
          <PreviewJobReport
            job={this.props.job}
            tasks={this.state.allTasks}
            reports={this.state.selectedRowCheck.sort((a, b) => a.report_order - b.report_order)}
            documents={this.state.selectedDocRowCheck}
            basePath={this.props.basePath}
            onEmail={this.handleOk}
            onDownload={this.downloadReport}
          />
        </Modal>

        <Modal
          className="cs-modal-v2"
          visible={this.state.emailJobReportModal}
          width="768px"
          centered
          bodyStyle={{ paddingRight: "0px", paddingLeft: "0px", maxHeight: "80vh", overflow: "hidden auto" }}
          footer={null}
          destroyOnClose={true}
          onCancel={() => this.setState({ emailJobReportModal: false, history: this.state.historyKey++, emailHistoryData: null })}
          closeIcon={<div className={v2Styles.ModalCloseIcon}><CloseIcon /></div>}
        >
          <EmailJobReport
            jobReports={this.state.selectedRowCheck}
            documents={this.state.selectedDocRowCheck}
            isSignOff={this.state.isSignOff}
            job={job}
            emailHistoryData={this.state.emailHistoryData}
            onClose={() => this.setState({
              emailJobReportModal: false,
              history: this.state.historyKey++,
              emailHistoryData: null
            })}
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    formValues:
      state.form && state.form.jobReport && state.form.jobReport.values
        ? state.form.jobReport.values
        : {},
    jobReports: state.sAJobMgmt.jobReports,
    job: state.sAJobMgmt.jobDetails,
    basePath: state.sAJobMgmt.basePath,
    filePath: state.sAJobMgmt.filePath,
    resizedFilePath: state.sAJobMgmt.resizedFilePath,
    versionCount: state.sAJobMgmt.versionCount,
    versionArray: state.sAJobMgmt.versions,
    jobDocument: state.sAJobMgmt.jobDocument,
    jobSignOffs: state.sAJobMgmt.jobSignOffs
  };
};

const mapDispatchToprops = (dispatch) => {
  return {
    action: bindActionCreators(actions, dispatch),
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToprops),
  reduxForm({
    form: "jobReport",
    onSubmitFail: (errors, dispatch, sub, props) => {
      handleFocus(errors, "#");
    },
  })
)(CleaningReport);
