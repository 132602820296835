import React, { useRef, useState, useEffect } from "react";
import TableRow from "./TableRow";
import { scopeApiInstance } from "../../../../dataProvider/axiosHelper";
import { Strings } from "../../../../dataProvider/localize";
import { notification } from "antd";
import { LinearProgress } from "@mui/material";

export default function TableView({ dataTable = {}, rowData = null, parentData = null, parentLoading, isChild = false }) {

  const controller = useRef();

  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (rowData) {
      setTableData(rowData);
    }
  }, [rowData])

  const getTableData = async () => {
    setLoading(true);
    controller.current = new AbortController();
    let fetchParams = {};
    if (dataTable.fetchParams && dataTable.fetchParams.length > 0) {
      dataTable.fetchParams.forEach(param => {
        fetchParams[param.key] = parentData[param.value]
      })
    }
    return scopeApiInstance.get(
      dataTable.apiEndPoint,
      {
        params: {
          ...fetchParams
        }, signal: controller.current.signal
      },
    ).then(res => {
      setLoading(false);
      if (res && res.data && res.data.data) {
        if (dataTable.dataGetter) {
          setTableData(dataTable.dataGetter(res.data.data));
        } else {
          setTableData(res.data.data.rows ? res.data.data.rows : []);
        }
      }
    }).catch(error => {
      if (error) {
        setLoading(false);
        notification.error({
          message: Strings.error_title,
          description: error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error,
          className: "ant-error"
        });
      }
    })
  }
  useEffect(() => {
    if (dataTable && dataTable.apiEndPoint && !isChild) {
      getTableData();
    }
    if (!dataTable.apiEndPoint && parentData && dataTable.keyName) {
      setTableData(parentData[dataTable.keyName])
    }
    return () => {
      if (controller.current) {
        controller.current.abort();
      }
    }
  }, []);

  return (
    <div {...(dataTable.tableContainerStyle ? { style: dataTable.tableContainerStyle } : {})}
    >
      <div style={{ height: "4px" }}>
        {loading || parentLoading ? (
          <LinearProgress />
        ) : ""}
      </div>
      <table
        className="table table-sm cs-table cs-table-v2"
        style={{ tableLayout: "fixed", width: "100%" }}
      >
        <thead {...(dataTable.tableHeaderStyle ? { style: dataTable.tableHeaderStyle } : {})}>
          <tr>
            {dataTable.innerTable ? (<th width="40"></th>) : null}
            {dataTable && dataTable.columns ? dataTable.columns.map((column, index) => (
              <th
                key={index}
                className={column.columnClassName}
                {...(column.width ? { width: column.width } : {})}>
                {column.columnName}
              </th>
            )) : null}
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, index) => (
            <TableRow
              key={dataTable.rowKey ? row[dataTable.rowKey] : index}
              dataTable={dataTable}
              row={{ ...row, parent: dataTable.keyName ? { ...parentData, [dataTable.keyName]: null } : null }}
              parentData={parentData}
            />
          ))
          }
          {!loading && !parentLoading && tableData && tableData.length === 0 ? (
            <tr>
              <td colSpan={dataTable.columns.length} className="text-center" style={{ color: "red" }}>{dataTable.noDataMessage ? dataTable.noDataMessage : "Data not Available."}</td>
            </tr>
          ) : null}
        </tbody>
      </table>
    </div>
  )
}