import { baseApiInstance, scopeApiInstance, scopeAxiosInstance } from "../../dataProvider/axiosHelper";
import {
  GET_JOBS_FOR_ROSTER,
  SET_ROSTER_DETAILS,
  GET_JOBS_SHIFTS_ROSTER,
  // GET_SHIFT_RESOURCES_ROSTER,
  GET_ROSTER_MANAGERS,
  SET_ROSTER_MANAGERS,
  GET_ROSTER_STATES,
  SET_ROSTER_STATES,
  ROSTER_SERVICE_TYPE,
  SET_ROSTER_SERVICES,
  GET_ROSTER_REGIONS,
  SET_ROSTER_REGIONS,
  GET_ROSTER_STAFFS,
  SET_ROSTER_STAFFS,
  ROSTERS_URL,
  SET_ROSTER_LISTS,
  GET_STAFFS_DAILY_VIEW,
  SET_STAFFS_DAILY_VIEW,
  GET_ROSTER_SHIFT_DETAILS,
  ROSTER_CREATE_ADD_SHIFT,
  PUBLISH_ROSTER,
  ROSTERS_SHIFT_STAFF_NOTIFY_URL,
  ROSTERS_SHIFT_NOTIFY_URL,
  GET_SWMS_LATEST_VERSIONS,
  ORGANIZATION_USERS_URL_V2,
  SHIFT_METADATA_URL,
  SUB_TASK_URL,
  CONFIRM_PLANNED_JOB_ROSTER_URL,
  GET_ALL_SITES_URL
} from "../../dataProvider/constant";
import { Strings } from "../../dataProvider/localize";

export const getRosterings = (week_start_date, week_end_date, region_id, roster_id = null) => dispatch => {
  let url = GET_JOBS_FOR_ROSTER;
  return scopeApiInstance.get(url, {
    params: {
      week_start_date: week_start_date,
      week_end_date: week_end_date,
      region_id: region_id,
      ...(roster_id ? { roster_id: roster_id } : {})
    }
  }).then(res => {
    if (res.data && res.data.data) {
      dispatch({
        type: SET_ROSTER_DETAILS,
        payload: res.data.data
      })
      return Promise.resolve(res.data.data.length > 0 ? res.data.data : []);
    } else {
      return Promise.reject();
    }
  }).catch(error => {
    return Promise.reject(
      error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
    );
  })
}

export const getJobShifts = (job_id, start_date, end_date) => dispatch => {
  let url = GET_JOBS_SHIFTS_ROSTER;
  return scopeApiInstance.get(url, {
    params: {
      job_id: job_id,
      week_start_date: start_date,
      week_end_date: end_date
    }
  }).then(res => {
    if (res.data && res.data.data) {
      return Promise.resolve(res.data.data.length > 0 ? res.data.data : []);
    } else {
      return Promise.reject();
    }
  }).catch(error => {
    return Promise.reject(
      error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
    );
  })
}

export const clearJobs = () => dispatch => {
  dispatch({
    type: SET_ROSTER_DETAILS,
    payload: []
  })
}


// export const getShiftResources = ( quote_task_id ) => dispatch => {
//   let url = GET_SHIFT_RESOURCES_ROSTER;
//   return scopeApiInstance.get(url, {
//     params: {
//       quote_task_id: quote_task_id
//     }
//   }).then(res => {
//     if (res.data && res.data.data) {
//       return Promise.resolve(res.data.data.length > 0 ? res.data.data : []);
//     } else {
//       return Promise.reject();
//     }
//   }).catch(error => {
//     return Promise.reject(
//       error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
//     );
//   })
// }

export const getManagers = (org_id) => dispatch => {
  let url = GET_ROSTER_MANAGERS;
  return baseApiInstance.get(url, {
    params: {
      organisation_id: org_id
    }
  }).then((res) => {
    if (res.data && res.data.data && res.data.data.account_managers) {
      dispatch({
        type: SET_ROSTER_MANAGERS,
        payload: res.data.data.account_managers
      })
      return Promise.resolve(res.data.data.account_managers);
    } else {
      return Promise.reject();
    }
  })
    .catch((error) => {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    })
}

export const getStates = () => dispatch => {
  let url = GET_ROSTER_STATES;
  return baseApiInstance.get(url).then((res) => {
    let data = res.data.data;
    if (data) {
      dispatch({
        type: SET_ROSTER_STATES,
        payload: data
      })
      return Promise.resolve(data);
    } else {
      return Promise.reject();
    }
  })
    .catch((error) => {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    })
}

export const getServiceType = (search = "") => dispatch => {
  let url = ROSTER_SERVICE_TYPE;
  if (search.trim() !== "") {
    url = `${url}?search=${search.trim()}`;
  }
  return scopeApiInstance.get(url).then((res) => {
    if (res.data && res.data.data) {
      dispatch({
        type: SET_ROSTER_SERVICES,
        payload: res.data.data
      })
      return Promise.resolve(res.data.data);
    } else {
      return Promise.reject();
    }
  })
    .catch((error) => {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    })
}

export const getRegions = (states, params = {}) => dispatch => {
  let url = GET_ROSTER_REGIONS;
  return scopeApiInstance.get(url, {
    params: {
      states: states,
      ...params
    }
  }).then(res => {
    if (res.data && res.data.data) {
      dispatch({
        type: SET_ROSTER_REGIONS,
        payload: res.data.data
      })
      return Promise.resolve(res.data.data.length > 0 ? res.data.data : []);
    } else {
      return Promise.reject();
    }
  }).catch(error => {
    return Promise.reject(
      error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
    );
  })
}

export const getStaffs = (state_id, start_date, end_date, employmentTypes = [], serviceTypes = null) => dispatch => {
  let url = GET_ROSTER_STAFFS;
  return scopeApiInstance.get(url, {
    params: {
      state_id,
      week_start_date: start_date,
      week_end_date: end_date,
      ...(employmentTypes.length ? { employment_type_id: employmentTypes.join(",") } : {}),
      ...(serviceTypes ? { service_type_id: serviceTypes.value } : {})
    }
  }).then((res) => {
    let data = res.data.data;
    if (data) {
      dispatch({
        type: SET_ROSTER_STAFFS,
        payload: data
      })
      return Promise.resolve(data);
    } else {
      return Promise.reject();
    }
  })
    .catch((error) => {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    })
}

export const getRosterList = (page = 1, state_id = null, region_id = null, status = null, week_start_date = null, week_end_date = null, limit = 20) => dispatch => {
  let url = ROSTERS_URL;
  return scopeApiInstance.get(url, {
    params: {
      limit,
      page,
      ...(state_id ? { state_id: state_id } : {}),
      ...(region_id ? { region_id: region_id } : {}),
      ...(status ? { status: status } : {}),
      ...(week_start_date ? { week_start_date: week_start_date } : {}),
      ...(week_end_date ? { week_end_date: week_end_date } : {}),
    }
  }).then((res) => {
    if (res.data && res.data.data && res.data.data.rows) {
      dispatch({
        type: SET_ROSTER_LISTS,
        payload: res.data.data
      })
      return Promise.resolve(res.data.data.rows);
    } else {
      return Promise.reject();
    }
  })
    .catch((error) => {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    })
}

export const getStaffDailyView = (staff_user_name, shift_date, signal) => dispatch => {
  let url = GET_STAFFS_DAILY_VIEW;
  return scopeApiInstance.get(url, {
    params: {
      staff_user_name: staff_user_name,
      shift_date: shift_date
    }, signal
  }).then((res) => {
    let data = res.data.data;
    if (data) {
      dispatch({
        type: SET_STAFFS_DAILY_VIEW,
        payload: data
      })
      return Promise.resolve(data);
    } else {
      return Promise.reject();
    }
  })
    .catch((error) => {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    })
}

export const getShiftDetails = (shift_id, signal) => dispatch => {
  let url = GET_ROSTER_SHIFT_DETAILS;
  return scopeApiInstance.get(url, {
    params: {
      job_schedule_shift_id: shift_id
    }, signal
  }).then(res => {
    if (res && res.data && res.data.data) {
      return Promise.resolve(res.data.data);
    } else {
      return Promise.reject();
    }
  }).catch((error) => {
    return Promise.reject(
      error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
    );
  })
}

export const allocateStaff = (formData) => dispatch => {
  let url = ROSTER_CREATE_ADD_SHIFT;
  return scopeApiInstance.post(url, formData).then(res => {
    if (res && res.data) {
      return Promise.resolve(res.data)
    } else {
      return Promise.reject()
    }
  }).catch((error) => {
    return Promise.reject(
      error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
    );
  })
}

export const publishRoster = (formData) => dispatch => {
  let url = PUBLISH_ROSTER;
  return scopeApiInstance.post(url, formData).then(res => {
    if (res && res.data && res.data.message) {
      return Promise.resolve(res.data.message)
    } else {
      return Promise.reject()
    }
  }).catch((error) => {
    return Promise.reject(
      error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
    );
  })
}

export const updateRoster = (formData) => dispatch => {
  let url = ROSTERS_URL;
  return scopeApiInstance.put(url, formData).then(res => {
    if (res && res.data && res.data.message) {
      return Promise.resolve(res.data.message)
    } else {
      return Promise.reject()
    }
  }).catch((error) => {
    return Promise.reject(
      error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
    );
  })
}

export const notifyStaff = (formData) => dispatch => {
  let url = ROSTERS_SHIFT_STAFF_NOTIFY_URL;
  return scopeApiInstance.post(url, formData).then(res => {
    if (res && res.data) {
      return Promise.resolve(res.data)
    } else {
      return Promise.reject()
    }
  }).catch((error) => {
    return Promise.reject(
      error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
    );
  })
}

export const notifyShifts = (formData) => dispatch => {
  let url = ROSTERS_SHIFT_NOTIFY_URL;
  return scopeApiInstance.post(url, formData).then(res => {
    if (res && res.data && res.data.message) {
      return Promise.resolve(res.data.message)
    } else {
      return Promise.reject()
    }
  }).catch((error) => {
    return Promise.reject(
      error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
    );
  })
}

export const getSwmsLatestVersions = (params = {}) => () => {
  return scopeApiInstance.get(
    GET_SWMS_LATEST_VERSIONS, {
    params: { ...params },
  })
    .then((res) => {
      if (res && res.data && res.data.data) {
        return Promise.resolve(res.data.data);
      } else {
        return Promise.reject(Strings.generic_error);
      }
    }).catch(error => {
      if (error) {
        return Promise.reject(
          error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
        );
      } else {
        return Promise.reject(null);
      }
    })
}


export const getAllStaffs = (abortController = {}, params = {}) => () => {
  return baseApiInstance.get(ORGANIZATION_USERS_URL_V2, { params: { ...params }, ...abortController }).then((res) => {
    if (res && res.data && res.data.data && res.data.data.orgUsers) {
      return Promise.resolve(res.data.data.orgUsers);
    } else {
      return Promise.reject(Strings.generic_error);
    }
  }).catch(error => {
    if (error) {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    } else {
      return Promise.reject(null);
    }
  })
}

export const getRosterShiftSubtasks = (abortController = {}, params = {}) => () => {
  return scopeApiInstance.get(SUB_TASK_URL, { params: { ...params }, ...abortController }).then((res) => {
    if (res && res.data && res.data.data) {
      return Promise.resolve(res.data.data);
    } else {
      return Promise.reject(Strings.generic_error);
    }
  }).catch(error => {
    if (error) {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    } else {
      return Promise.reject(null);
    }
  })
}

export const getRosterShiftDetails = (abortController = {}, params = {}) => () => {
  return scopeApiInstance.get(SHIFT_METADATA_URL, { params: { ...params }, ...abortController }).then((res) => {
    if (res && res.data && res.data.data) {
      return Promise.resolve(res.data.data);
    } else {
      return Promise.reject(Strings.generic_error);
    }
  }).catch(error => {
    if (error) {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    } else {
      return Promise.reject(null);
    }
  })
}

export const savePlannedRosterShiftMetadata = (formData) => () => {
  let url = SHIFT_METADATA_URL;
  return scopeApiInstance.put(url, formData).then(res => {
    if (res && res.data) {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject();
    }
  }).catch((error) => {
    return Promise.reject(
      error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
    );
  })
}

export const confirmRosterSave = (formData) => () => {
  let url = CONFIRM_PLANNED_JOB_ROSTER_URL;
  return scopeApiInstance.put(url, formData).then(res => {
    if (res && res.data) {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject();
    }
  }).catch((error) => {
    return Promise.reject(
      error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
    );
  })
}

export const getRosterSiteDetails = (id) => () => {
  return scopeApiInstance.get(GET_ALL_SITES_URL, {
    params: {
      id: id
    },
  }).then((res) => {
    if (res.data && res.data.data && res.data.data && res.data.data.sitesList) {
      return Promise.resolve(res.data.data);
    }
    else {
      return Promise.reject();
    }
  }).catch((error) => {
    if (error) {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    } else {
      return Promise.reject(null);
    }
  })
}